// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-api-negosyonow-js": () => import("./../../../src/pages/api/negosyonow.js" /* webpackChunkName: "component---src-pages-api-negosyonow-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-guide-remote-gatsby-develop-js": () => import("./../../../src/pages/guide/remote-gatsby-develop.js" /* webpackChunkName: "component---src-pages-guide-remote-gatsby-develop-js" */),
  "component---src-pages-guide-rename-xcode-project-js": () => import("./../../../src/pages/guide/rename-xcode-project.js" /* webpackChunkName: "component---src-pages-guide-rename-xcode-project-js" */),
  "component---src-pages-guide-ssh-notepad-pplus-js": () => import("./../../../src/pages/guide/ssh-notepad-pplus.js" /* webpackChunkName: "component---src-pages-guide-ssh-notepad-pplus-js" */),
  "component---src-pages-guide-ssh-sublime-js": () => import("./../../../src/pages/guide/ssh-sublime.js" /* webpackChunkName: "component---src-pages-guide-ssh-sublime-js" */),
  "component---src-pages-guide-ssh-to-aws-server-js": () => import("./../../../src/pages/guide/ssh-to-aws-server.js" /* webpackChunkName: "component---src-pages-guide-ssh-to-aws-server-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-reference-git-maintenance-js": () => import("./../../../src/pages/reference/git-maintenance.js" /* webpackChunkName: "component---src-pages-reference-git-maintenance-js" */),
  "component---src-pages-reference-js": () => import("./../../../src/pages/reference.js" /* webpackChunkName: "component---src-pages-reference-js" */),
  "component---src-pages-technical-js": () => import("./../../../src/pages/technical.js" /* webpackChunkName: "component---src-pages-technical-js" */),
  "component---src-pages-testresult-js": () => import("./../../../src/pages/testresult.js" /* webpackChunkName: "component---src-pages-testresult-js" */),
  "component---src-pages-testresult-negosyonow-selenium-js": () => import("./../../../src/pages/testresult/negosyonow-selenium.js" /* webpackChunkName: "component---src-pages-testresult-negosyonow-selenium-js" */)
}

