module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"IAMNET Documents Portal","short_name":"IAMNET Documents Portal","start_url":"/","background_color":"#FFFFFF","theme_color":"#481e5d","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c1ad5a088f0b8cee44db7cedb54495ee"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://documents.iamnet.ph"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
